import { Button } from "@mui/material"
import { ethers } from "ethers"
/**
 * A: if the chain id exits in keplr, can it be add the same id one?
 * W: can not be add, and there are not any errors. even you added chain id mychain-1, then mychain-2 or mychain can not be added
 * 
 * currencies must contain feeCurrencies and stakeCurrency, otherwise, keplr will show blank page
 * 
 * coinGeckoId/walletUrlForStaking can not be empty string, use undefined instead
 * 
 * stakeCurrency will show on the top in keplr, that means stakeCurrency is the main currency? if other currency balance is empty, they will not show
 * 
 * A: what if there are 2 some object in currencies?
 * W: then it will be show twice in keplr, so be sure remove the some currencies
 * 
 * if stakeCurrency's coinGeckoId exits, keplr will show USD value. other currencies will not show USD value evenif coinGeckoId exists
 * 
 * if assets not in currencies, keplr will not show it
 * 
 * gas fee asset only show the first one of feeCurrencies in keplr, so gas fee just need one
 */
export default function Dev() {
  async function submit() {
    console.log( ethers.utils.hexlify( ethers.utils.toUtf8Bytes('"chain_type":"JOLTIFY"') ) )
  }
  return (
    <div className="flex justify-center items-center min-h-screen">
      <Button onClick={() => submit()} variant="contained">Submit</Button>
    </div>
  )
}