import { Box, TextField, Button, Typography, FormControl, InputLabel, Select, MenuItem } from "@mui/material"
import { useState } from "react"
import CircularProgress from '@mui/material/CircularProgress'
const methods = ['GET', 'POST', 'PUT', 'DELETE', 'HEAD', 'CONNECT', 'OPTIONS', 'TRACE', 'PATCH']
export default function CorsChecker() {
  const [inputValue, setInputValue] = useState('')
  const [urlsInfo, setUrlsInfo] = useState<{url: string, cors: string}[]>([])
  const [checking, setChecking] = useState(false)
  const [method, setMethod] = useState('GET')
  async function check() {
    let _urlsInfo = []
    setChecking(true)
    setUrlsInfo([])
    for (const url of inputValue.split('\n')) {
      if (!url.trim() || _urlsInfo.filter(urlInfo=>{return urlInfo.url===url.trim()}).length>0 ) continue
      let urlInfo = {url: url.trim(), cors: '❔'}
      let res
      try {
        res = await fetch(url.trim(), {method})
      } catch(e) {}
      // res?.text().then(text=>console.log(method, url.trim(), text)) // reveal in console-network
      if ('cors'===res?.type) {
        urlInfo.cors = '✅'
      } else {
        urlInfo.cors = '❌'
      }
      _urlsInfo.push(urlInfo)
    }
    setChecking(false)
    setUrlsInfo(_urlsInfo)
  }
  return (
    <Box display='flex' alignItems='center' justifyContent='center' minHeight='62vh'>
      <Box maxWidth='800px' width='100%'>
        <Typography variant="h3" component="div" gutterBottom>
          CORS Checker
        </Typography>
        <TextField
          fullWidth
          label="One url per line"
          multiline
          rows={8}
          value={inputValue}
          onChange={(e)=>setInputValue(e.target.value)}
        />
        <FormControl variant="standard" className="w-28 mr-4">
          <InputLabel id="request-method-select-label"></InputLabel>
          <Select
            labelId="request-method-select-label"
            id="demo-simple-select"
            value={method}
            label=""
            onChange={(e)=>setMethod(e.target.value)}
          >
            {methods.map(method=>(<MenuItem value={method} key={`request-method-${method}`}>{method}</MenuItem>))}
          </Select>
        </FormControl>
        <Button onClick={()=>check()} disabled={checking} variant="contained" className="my-4">
          {checking&&<CircularProgress color='inherit' size='1rem' value={50} className='mr-2' />}
          {checking?'Checking':'Check'}
        </Button>
        {urlsInfo.map(urlInfo=>(
          <div key={urlInfo.url+urlInfo.cors}>{urlInfo.cors} {urlInfo.url}</div>
        ))}
      </Box>
    </Box>
  )
}

/*
https://data-seed-prebsc-1-s1.binance.org:8545/
https://data-seed-prebsc-2-s1.binance.org:8545/
http://data-seed-prebsc-1-s2.binance.org:8545/
http://data-seed-prebsc-2-s2.binance.org:8545/
https://data-seed-prebsc-1-s3.binance.org:8545/
https://data-seed-prebsc-2-s3.binance.org:8545/
*/