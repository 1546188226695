import { Box, Button, TextField, Typography, ButtonGroup } from "@mui/material";
import { useEffect, useState } from "react"
import {Bech32Address} from '@keplr-wallet/cosmos'
import { ethers } from "ethers"
import {pubkeyToAddress, encodeSecp256k1Pubkey, decodeBech32Pubkey, encodeBech32Pubkey, pubkeyType} from '@cosmjs/amino'

const bip39 = require('bip39')

const defaults = {
  prefix: 'cosmos'
}

export default function Cosmos() {
  const [prefix, setPrefix] = useState('')
  const [privKey, setPrivKey] = useState('')
  const [pubKey, setPubKey] = useState('')
  const [pubKeyCompressed, setPubKeyCompressed] = useState('')
  const [pubKeyCompressedBytes, setPubKeyCompressedBytes] = useState('') // convert by watching pubKeyCompressed change or set empty
  const [bech32Addr, setBech32Addr] = useState('')
  const [accAddr, setAccAddr] = useState('')
  const [accAddrBytes, setAccAddrBytes] = useState('')
  const [evmAddr, setEvmAddr] = useState('')
  const [usePrefix, setUsePrefix] = useState(defaults.prefix)
  const [prefixedPub, setPrefixedPub] = useState('')
  const [mnemonic, setMnemonic] = useState('')

  useEffect(()=>{
    if (prefix?.trim()){
      setUsePrefix(prefix.trim())
      return
    }
    setUsePrefix(defaults.prefix)
  }, [prefix])

  useEffect(()=>{
    const domInputs = document.getElementsByTagName('input')
    for (let i=0; i<domInputs.length; i++) domInputs[i].autocomplete = 'off'
  }, [])

  useEffect(()=>{
    try {
      setPubKeyCompressedBytes(ethers.utils.arrayify(pubKeyCompressed).toString())
    } catch(e) {
      setPubKeyCompressedBytes('')
    }
  }, [pubKeyCompressed])

  function convertClick() {
    if (mnemonic) {
      convertMnemonic(mnemonic)
      return
    }

    if (privKey) {
      convertPrivKey(privKey)
      return
    }
    
    if (pubKey) {
      convertPubKey(pubKey)
      return
    }
    
    if (pubKeyCompressed) {
      convertComprisedPubKey(pubKeyCompressed)
      return
    }

    if (prefixedPub) {
      convertPrefixedPubKey(prefixedPub)
      return
    }

    if (bech32Addr) {
      convertBech32Addr(bech32Addr)
      return
    }

    if (accAddr) {
      convertAccAddr(accAddr)
      return
    }
  }

  function generateMnemonic() {
    const _mnemonic = bip39.generateMnemonic()
    setMnemonic(_mnemonic)
    const mnemonicWallet = ethers.Wallet.fromMnemonic(_mnemonic)
    const _privKey = mnemonicWallet.privateKey
    setPrivKey(_privKey)
    convertPrivKey(_privKey)
  }

  function convertMnemonic(_mnemonic: string) {
    try {
      const mnemonicWallet = ethers.Wallet.fromMnemonic(_mnemonic)
      setPrivKey(mnemonicWallet.privateKey)
      setPubKey(mnemonicWallet.publicKey)
      setEvmAddr(mnemonicWallet.address)
      convertPubKey(mnemonicWallet.publicKey)
    } catch(e:any) {

    }
  }

  function convertPrivKey(_privKey: string) {
    try {
      const wallet = new ethers.Wallet(_privKey)
      setPubKey(wallet.publicKey)
      setEvmAddr(wallet.address)
      convertPubKey(wallet.publicKey)
    } catch(e: any) {
      alert(e.message)
      setPubKey('')
    }
  }

  function convertPubKey(_pubKey: string) {
    try {
      setEvmAddr(ethers.utils.computeAddress(_pubKey))
      const _pubKeyCompressed = ethers.utils.computePublicKey(_pubKey, true)
      setPubKeyCompressed(_pubKeyCompressed)
      convertComprisedPubKey(_pubKeyCompressed)
    } catch(e: any) {
      alert(e.message)
      setPubKeyCompressed('')
    }
  }

  function convertComprisedPubKey(_pubKeyCompressed: string) {
    try {
      setPrefixedPub( encodeBech32Pubkey({type: pubkeyType.secp256k1, value: ethers.utils.base64.encode(_pubKeyCompressed)}, `${usePrefix}pub`) )
      setEvmAddr(ethers.utils.computeAddress(_pubKeyCompressed))
      const _pubKeyCompressedBytes = ethers.utils.arrayify(_pubKeyCompressed)
      const _bech32Addr = pubkeyToAddress(encodeSecp256k1Pubkey(_pubKeyCompressedBytes), usePrefix)
      setBech32Addr(_bech32Addr)
      convertBech32Addr(_bech32Addr)
    } catch(e: any) {
      alert(e.message)
      setBech32Addr('')
      setEvmAddr('')
    }
  }

  function convertPrefixedPubKey(prefixedPub: string) {
    try {
      const pubKeyCompressed = ethers.utils.hexlify(ethers.utils.base64.decode(decodeBech32Pubkey(prefixedPub).value))
      convertComprisedPubKey(pubKeyCompressed)
      setPubKeyCompressed(pubKeyCompressed)
    } catch(e: any) {
      alert(e.message)
      setPubKeyCompressed('')
    }
  }

  function convertBech32Addr(_bech32Addr: string) {
    try {
      const _accAddr = Bech32Address.fromBech32(_bech32Addr, usePrefix).address
      setAccAddr(ethers.utils.hexlify(_accAddr))
    } catch(e: any) {
      alert(e.message)
      setAccAddr('')
    }
  }

  function convertAccAddr(_accAddr: string) {
    try {
      const _bench32Address = new Bech32Address(ethers.utils.arrayify(_accAddr))
      setBech32Addr(_bench32Address.toBech32(usePrefix))
    } catch(e: any) {
      alert(e.message)
      setBech32Addr('')
    }
  }

  return (
    <Box className="flex flex-col items-center justify-center min-h-[62vh] max-w-5xl mx-auto">
      <Typography variant="h3" gutterBottom>Cosmos address convertor</Typography>

      <TextField label='Prefix' onChange={(e)=>setPrefix(e.target.value?.trim())} value={prefix} placeholder="cosmos" fullWidth className="mb-4"></TextField>

      <Box className="flex mb-4 w-full">
        <TextField label='Mnemonic' onChange={(e)=>setMnemonic(e.target.value?.trim())} value={mnemonic} className="flex-grow mr-4"></TextField>
        <Button onClick={()=>generateMnemonic()} variant="contained" >Generate</Button>
      </Box>

      <TextField label='Private Key' onChange={(e)=>setPrivKey(e.target.value?.trim())} value={privKey} fullWidth className="mb-4"></TextField>

      <TextField label='Public Key' onChange={(e)=>setPubKey(e.target.value?.trim())} value={pubKey} rows={2} multiline fullWidth className="mb-4"></TextField>

      <Box className="mb-4 w-full">
        <TextField label='Public Key Compressed' onChange={(e)=>setPubKeyCompressed(e.target.value?.trim())} value={pubKeyCompressed} fullWidth></TextField>
        <Typography className="break-words">bytes: [{pubKeyCompressedBytes}]</Typography>
      </Box>

      <TextField label='Public Key Prefixed' onChange={(e)=>setPrefixedPub(e.target.value?.trim())} value={prefixedPub} fullWidth className="mb-4"></TextField>

      <TextField label='Bech32 Addrêss' onChange={(e)=>setBech32Addr(e.target.value?.trim())} value={bech32Addr} fullWidth className="mb-4"></TextField>

      <Box className="mb-4 w-full">
        <TextField label='Acc Addrêss' onChange={(e)=>setAccAddr(e.target.value?.trim())} value={accAddr} fullWidth></TextField>
        {/* <Typography>bytes: [{accAddrBytes}]</Typography> */}
      </Box>
      
      <TextField label='EVM Addrêss' onChange={(e)=>setEvmAddr(e.target.value?.trim())} value={evmAddr} fullWidth className="mb-4"></TextField>

      <ButtonGroup variant="contained" fullWidth size="large">
        <Button onClick={()=>{
          setPrivKey(''); setPubKey(''); setBech32Addr(''); setAccAddr(''); setEvmAddr(''); setMnemonic('');
          setAccAddrBytes(''); setPubKeyCompressed(''); setPubKeyCompressedBytes(''); setPrefixedPub('')
        }}>Clear</Button>
        <Button onClick={()=>convertClick()}>Convert</Button>
      </ButtonGroup>
    </Box>
  )
}