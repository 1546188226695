import { Box, Button, TextField, Typography } from "@mui/material"
import {PrivKeySecp256k1} from '@keplr-wallet/crypto'
import {Bech32Address} from '@keplr-wallet/cosmos'
import { ethers } from "ethers"
import { useState } from "react"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio'

const TronWeb = require('tronweb')
const tronWeb = new TronWeb({fullHost: 'http://127.0.0.1'})

// type Account = {address: string, privateKey: string}

export default function WalletGenerator() {
  const [prefix, setPrefix] = useState('')
  const [repeatEnd, setRepeatEnd] = useState('')
  const [chain, setChain] = useState('cosmos')
  const [accounts, setAccounts] = useState<{address: string, privateKey: string}[]>([])
  const [tryTimes] = useState('100000')

  async function generate() {
    // generateEvmAddress(); return
    alert('It might take a while, please wait')
    let newAccount = {address: '', privateKey: ''}
    if ('cosmos'===chain) {
      newAccount = generateCosmosAddress(prefix, repeatEnd)
    } else if ('tron'===chain) {
      newAccount = await generateTronAddress(repeatEnd)
    } else if ('evm'===chain) {
      newAccount = generateEvmAddress(repeatEnd)
    }
    console.log(newAccount)
    if (newAccount?.address) {
      setAccounts([...accounts, newAccount])
    } else {
      alert('Generate faild')
    }
  }

  return (
    <Box className="flex flex-col items-center justify-center min-h-[62vh] max-w-fit mx-auto">
      <Typography variant="h2" gutterBottom component="div">
        Crypto Wallet Generator
      </Typography>

      <Box className="select-none">
        <Radio
          id="selector-cosmos"
          checked={chain === 'cosmos'}
          onChange={(e)=>setChain(e.target.value)}
          value="cosmos"
          name="chain-selector"
        />
        <label htmlFor="selector-cosmos">Cosmos</label>
        <Radio
          id="selector-tron"
          checked={chain === 'tron'}
          onChange={(e)=>setChain(e.target.value)}
          value="tron"
          name="chain-selector"
        />
        <label htmlFor="selector-tron">Tron</label>
        <Radio
          id="selector-evm"
          checked={chain === 'evm'}
          onChange={(e)=>setChain(e.target.value)}
          value="evm"
          name="chain-selector"
        />
        <label htmlFor="selector-evm">EVM</label>
      </Box>

      <Box className="flex my-4">
        {'cosmos'===chain&&<TextField onChange={(e)=>setPrefix(e.target.value)} value={prefix} className='mr-4' size='small' label='Prefix' variant="outlined" placeholder="cosmos"></TextField>}
        <TextField onChange={(e)=>setRepeatEnd(e.target.value)} value={repeatEnd} size='small' label='Repeat end' variant="outlined" placeholder="3" type='number' autoComplete='off'></TextField>
        <Button onClick={()=>generate()} className='ml-4' size='small' variant='contained'>Generate</Button>
      </Box>
      
      {accounts.length>0&&<TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Address</TableCell>
              <TableCell>Private Key</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {accounts.map(account=>(
              <TableRow key={account.address}>
                <TableCell>{account.address}</TableCell>
                <TableCell>{account.privateKey}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>}

      {accounts.length>0&&<Typography variant="body2" component="p" className="mt-4">
        * Althrough we do not collect your private key, but it is strongly not recommanded to use these generated wallets as your primary wallets, please use them just for test.
      </Typography>}
    </Box>
  )
}

function generateEvmAddress(endLength='3', tryTimes = '100000') {
  let wallet
  let count = 0
  do {
    wallet = ethers.Wallet.createRandom()
  } while( !sameLetters(wallet.address.substring(wallet.address.length-Number(endLength||3))) && count++<Number(tryTimes||100000) )
  return {address: wallet.address, privateKey: wallet.privateKey}
}

async function generateTronAddress(endLength='3', tryTimes = '100000') {
  let address = ''
  let privateKey = ''
  let count = 0
  while((0===address.length || !sameLetters(address.substring(34-Number(endLength||'3')))) && count++<Number(tryTimes||100000) ) {
    const account:{privateKey: string, publicKey: string, address: {base58: string,hex: string}} = await tronWeb.createAccount()
    address = account.address.base58
    if (sameLetters(address.substring(34-Number(endLength||'3')))) {
      privateKey = account.privateKey
      break
    }
  }
  return {address, privateKey}
}

function generateCosmosAddress(prefix='cosmos', endLength='3', tryTimes = '100000') {
  prefix = prefix?.trim()
  if (!prefix) prefix = 'cosmos'
  let addressPrefixed = ''
  let privKeySecp256k1: PrivKeySecp256k1
  let addressEnd = ''
  let count = 0
  do {
    privKeySecp256k1 = PrivKeySecp256k1.generateRandomKey()
    const pubKeySecp256k1 = privKeySecp256k1.getPubKey()
    const bech32Address = new Bech32Address(pubKeySecp256k1.getAddress())
    addressPrefixed = bech32Address.toBech32(prefix)
    addressEnd = addressPrefixed.substring(addressPrefixed.length-Number(endLength||3))
    // console.log('addressEnd', addressEnd, sameLetters(addressEnd))
  } while( !sameLetters(addressEnd) && count++<Number(tryTimes||100000) )
  return {
    address: addressPrefixed, privateKey: ethers.utils.hexlify(privKeySecp256k1.toBytes())
  }
}

function sameLetters(text: string) {
  const _text = text?.trim().toLowerCase()
  const textLength = _text.length
  for (let i=1; i<textLength; i++) {
    if (_text[i]!==_text[i-1]) {
      return false
    }
  }
  return true
}