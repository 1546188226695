import { Box, Typography } from "@mui/material"
import { useEffect, useState } from "react"

let pastListening = false

export default function Notepad() {
  const [stringLength, setStringLength] = useState(0)
  useEffect(()=>{
    const dom = document.getElementById('notepad')
    if (!dom) return
    dom.innerText = localStorage.getItem('notepadContent')||''
    setStringLength(dom.innerText.length)
    setCursorEnd(dom)
    dom.addEventListener('input', ()=>{
      console.log('dom.innerText.length', dom.innerText.length)
      setStringLength(dom.innerText.length)
      localStorage.setItem('notepadContent', dom.innerText)
    })

    if (pastListening) return
    pastListening = true
    dom.addEventListener('paste', (e: any) => {
      e.preventDefault()
      const textPasted = e.clipboardData.getData("text/plain")
      if (!document.execCommand('insertText', false, textPasted)) {
        const selection = window.getSelection();
        const range = selection?.getRangeAt(0);
        range?.deleteContents();
        const node = document.createTextNode(textPasted);
        range?.insertNode(node)
      }
      setCursorEnd(dom)
    })
  }, [])

  function setCursorEnd(dom: HTMLElement) {
    const range = document.createRange()
    range.selectNodeContents(dom)
    range.collapse(false)
    const selection = window.getSelection()
    selection?.removeAllRanges()
    selection?.addRange(range)
  }
  
  return (
    <Box>
      <Typography variant="body2" component="div" marginBottom='1em'>
        Length: {stringLength}
      </Typography>
      <div id="notepad" contentEditable spellCheck="false"
        style={{width: '100%', boxSizing: 'border-box', 
          minHeight: '62vh', outline: 'none'}}
      ></div>
    </Box>
  )
}