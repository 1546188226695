import {Toolbar, Divider, List, ListItem, ListItemButton, ListItemText, ListItemIcon, Icon, IconButton, Typography, Box} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';

export default function DrawerContents(props: {setMobileOpenFalse: Function, showCloseBtn?: boolean}) {
  const navigate = useNavigate()
  return (
    <Box>
      <Toolbar sx={{justifyContent: 'right'}}>
        {props.showCloseBtn&&<IconButton onClick={()=>props.setMobileOpenFalse()}><KeyboardDoubleArrowLeftIcon /></IconButton>}
      </Toolbar>
      <Divider />
      <List>
        <ListItem disablePadding><ListItemButton onClick={()=>{navigate('keplr'); props.setMobileOpenFalse()}}><ListItemText primary='Keplr' /></ListItemButton></ListItem>
        <ListItem disablePadding><ListItemButton onClick={()=>{navigate('cosmos'); props.setMobileOpenFalse()}}><ListItemText primary='Cosmos Convertor' /></ListItemButton></ListItem>
        <ListItem disablePadding><ListItemButton onClick={()=>{navigate('wallet-generator'); props.setMobileOpenFalse()}}><ListItemText primary='Wallet Generator' /></ListItemButton></ListItem>
        <ListItem disablePadding><ListItemButton onClick={()=>{navigate('notepad'); props.setMobileOpenFalse()}}><ListItemText primary='Notepad' /></ListItemButton></ListItem>
        <ListItem disablePadding><ListItemButton onClick={()=>{navigate('cors-checker'); props.setMobileOpenFalse()}}><ListItemText primary='CORS Checker' /></ListItemButton></ListItem>
        <ListItem disablePadding><ListItemButton onClick={()=>{navigate('base64'); props.setMobileOpenFalse()}}><ListItemText primary='Base64' /></ListItemButton></ListItem>
        <ListItem disablePadding><ListItemButton onClick={()=>{navigate('terra'); props.setMobileOpenFalse()}}><ListItemText primary='Terra' /></ListItemButton></ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={()=>props.setMobileOpenFalse()} href='https://etherscan.io/unitconverter' target='_blank'>
            <ListItemText primary='Unit Converter' />
            <ListItemIcon><Icon fontSize='small'>launch</Icon></ListItemIcon>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton href='https://bscscan.com/tokenapprovalchecker' onClick={()=>props.setMobileOpenFalse()} target='_blank'>
            <ListItemText primary='Approval-BSC' />
            <ListItemIcon><Icon fontSize='small'>launch</Icon></ListItemIcon>
          </ListItemButton>
        </ListItem>
        {/* {[1,2,3,4,5,6,7,8,9,1,2,3,4,5,6,7,8,9].map(_=>(
          <ListItem disablePadding key={Math.random()}><ListItemButton><ListItemText primary='Test' /></ListItemButton></ListItem>
        ))} */}
      </List>
      <Box className='text-center absolute bottom-3 w-full'>
        <Typography className='text-gray-700 text-xs'>v{process.env.REACT_APP_VERSION}</Typography>
      </Box>
    </Box>
  )
}