import React from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import {useMediaQuery, CssBaseline} from '@mui/material'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import TerraPage from './pages/TerraPage/TerraPage';
import Main from './pages/Main/Main';
import Base64Page from './pages/Base64Page/Base64Page';
import Notepad from './pages/Notepad/Notepad';
import Keplr from './pages/Keplr/Keplr';
// import KeplrParamsTest from './pages/Dev/KeplrParamsTest';
// import Decimals from './pages/Decimals/Decimals';
import CorsChecker from './pages/CorsChecker/CorsChecker';
import WalletGenerator from './pages/WalletGenerator/WalletGenerator';
import Cosmos from './pages/Cosmos/Cosmos';
import Dev from './pages/Dev/Dev';

export default function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
        },
      }),
    [prefersDarkMode],
  )
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path='/' element={<Main />}>
            <Route index element={<Keplr />} />
            <Route path='base64' element={<Base64Page />} />
            <Route path='terra' element={<TerraPage />} />
            <Route path='notepad' element={<Notepad />} />
            <Route path='keplr' element={<Keplr />} />
            <Route path='cors-checker' element={<CorsChecker />} />
            <Route path='wallet-generator' element={<WalletGenerator />} />
            <Route path='cosmos' element={<Cosmos />} />
            <Route path='dev' element={<Dev />} />
            {/* <Route path='decimals' element={<Decimals />} /> */}
            {/* <Route path='dev/keplr-params-test' element={<KeplrParamsTest />} /> */}
          </Route>
        </Routes>
      </Router>
    </ThemeProvider>
  )
}