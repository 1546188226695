import {TextField, Typography, Button, ButtonGroup, RadioGroup, Radio, FormControlLabel, FormControl} from '@mui/material'
import { useState } from 'react'
import { Base64 } from 'js-base64'
import { ethers } from 'ethers'

/**
 * string base64
 * array base64
 */

export default function Base64Page() {
  const [inputString, setInputString] = useState('')
  const [resultString, setResultString] = useState('')
  const [algorithm] = useState('base64')
  const [dataFormat, setDataFormat] = useState<'string'|'hex'>('string') // string, hex

  function btnClickedEncode() {
    if ('string'===dataFormat) {
      setResultString(Base64.encode(inputString))
    } else if ('hex'===dataFormat) {
      if ('base64'===algorithm) {
        setResultString(ethers.utils.base64.encode(inputString).toString())
      } else if ('base58'===algorithm) {
        setResultString(ethers.utils.base58.encode(inputString).toString())
      }
    }
  }
  function btnClickedDecode() {
    if ('string'===dataFormat) {
      setResultString(Base64.decode(inputString))
    } else if ('hex'===dataFormat) {
      setResultString(ethers.utils.base64.decode(inputString).toString())
    }
  }
  return (
    <div style={{maxWidth: '800px', minHeight: '62vh', margin: '0 auto'}}>
      <Typography variant="h3" component="div" gutterBottom>
        Base64 encoding and decoding
      </Typography>

      <TextField
          label="Input text"
          multiline
          rows={6}
          value={inputString}
          onChange={(e)=>setInputString(e.currentTarget.value)}
          fullWidth
          placeholder={'hex'===dataFormat?'0x':''}
        />

      <div style={{margin: '20px 0'}}>
        {/* <FormControl>
          <RadioGroup
            defaultValue="base64"
            row
            onChange={(e)=>setAlgorithm(e.target.value)}
          >
            <FormControlLabel value="base64" control={<Radio />} label="base64" />
            <FormControlLabel value="base58" control={<Radio />} label="base58" />
          </RadioGroup>
        </FormControl> */}

        <ButtonGroup variant="contained" aria-label="outlined primary button group">
          <Button onClick={()=>btnClickedDecode()}>Decode</Button>
          <Button onClick={()=>btnClickedEncode()}>Encode</Button>
        </ButtonGroup>

        <FormControl style={{marginLeft: '20px'}}>
          <RadioGroup
            defaultValue="string"
            row
            onChange={({target: {value}})=>{setDataFormat(value as 'string'|'hex')}}
          >
            <FormControlLabel value="string" control={<Radio />} label="String" />
            <FormControlLabel value="hex" control={<Radio />} label="Hex" />
          </RadioGroup>
        </FormControl>
      </div>

      <TextField
          label="Result shows here"
          multiline
          rows={6}
          value={resultString}
          fullWidth
        />
    </div>
  )
}