import { RawKey } from '@terra-money/terra.js'
import {TextField, Typography, Button, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton} from '@mui/material'
import { useState } from 'react'
import { Base64 } from 'js-base64'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import produce from 'immer'
const CryptoJS = require("crypto-js")

export default function TerraPage() {

  const [walletName, setWalletName] = useState('')
  const [resultString, setResultString] = useState('')
  const [password, setPassword] = useState({value: '', visibale: false})
  const [privateKey, setPrivateKey] = useState({value: '', visibale: false})

  function btnClicked() {
    const _password = password.value.trim()
    if (!_password) return
    const rawKey = new RawKey(Buffer.from(privateKey.value.trim(), 'hex'))
    const jsonString = JSON.stringify({
      name: walletName,
      address: rawKey.accAddress,
      encrypted_key: encrypt(privateKey.value, password.value)
    })
    setResultString(Base64.encode(jsonString))
  }
  return (
    <div style={{maxWidth: '800px', minHeight: '62vh', margin: '0 auto'}}>
      <Typography variant="h3" component="div" gutterBottom>
        Convert private key to Terra key
      </Typography>

      <TextField
          label="Wallet name"
          value={walletName}
          onChange={(e)=>setWalletName(e.target.value)}
          fullWidth
        />
      
      <FormControl sx={{ width: '100%', mt: '20px' }} variant="outlined">
        <InputLabel htmlFor="outlined-adornment-password">Wallet password *</InputLabel>
        <OutlinedInput
          autoComplete='off'
          readOnly
          onFocus={(e)=>e.target.removeAttribute('readonly')}
          id="outlined-adornment-password"
          type={password.visibale ? 'text' : 'password'}
          value={password.value}
          onChange={(e)=>setPassword(produce(draft =>{draft.value = e.target.value}))}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={()=>setPassword(produce(draft =>{draft.visibale = !password.visibale}))}
                edge="end"
              >
                {password.visibale ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          label="Wallet password *"
        />
      </FormControl>

      <FormControl sx={{ width: '100%', mt: '20px' }} variant="outlined">
        <InputLabel htmlFor="outlined-adornment-privatekey">Private key *</InputLabel>
        <OutlinedInput
          autoComplete='off'
          readOnly
          onFocus={(e)=>e.target.removeAttribute('readonly')}
          id="outlined-adornment-privatekey"
          type={privateKey.visibale ? 'text' : 'password'}
          value={privateKey.value}
          onChange={(e)=>setPrivateKey(produce(draft =>{draft.value = e.target.value}))}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={()=>setPrivateKey(produce(draft =>{draft.visibale = !privateKey.visibale}))}
                edge="end"
              >
                {privateKey.visibale ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          label="Private key *"
        />
      </FormControl>

      <div style={{margin: '20px 0'}}>
        <Button variant='contained' onClick={()=>btnClicked()}>Convert</Button>
      </div>

      <TextField
          label="Result shows here"
          multiline
          rows={7}
          value={resultString}
          fullWidth
        />

      <Typography style={{marginTop: '20px', wordBreak: 'break-all'}} variant="body2" component="div">
        * Although we do not collect private keys, it is strongly not recommended that you take important private keys to convert here, you can use this script to convert offline:
        https://github.com/LanfordCai/terra_converter/blob/main/index.js
      </Typography>
    </div>
  )
}

// https://github.com/terra-money/station-mobile/blob/3ec15b9a620432dee47378f5b6e621d93780748a/src/utils/crypto.ts
const keySize = 256
const iterations = 100
function encrypt (
  message: string,
  password: string
): string  {
  try {
    const salt = CryptoJS.lib.WordArray.random(128 / 8)
    const key = CryptoJS.PBKDF2(password, salt, {
      keySize: keySize / 32,
      iterations,
    })

    const iv = CryptoJS.lib.WordArray.random(128 / 8)
    const encrypted = CryptoJS.AES.encrypt(message, key, {
      iv,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
    })

    const transitmessage =
      salt.toString() + iv.toString() + encrypted.toString()

    return transitmessage
  } catch (error) {
    console.error(error)
    return ''
  }
}

// function decrypt (
//   transitmessage: string,
//   password: string
// ): string {
//   try {
//     const salt = CryptoJS.enc.Hex.parse(transitmessage.substr(0, 32))
//     const iv = CryptoJS.enc.Hex.parse(transitmessage.substr(32, 32))
//     const encrypted = transitmessage.substring(64)
//     const key = CryptoJS.PBKDF2(password, salt, {
//       keySize: keySize / 32,
//       iterations,
//     })

//     const decrypted = CryptoJS.AES.decrypt(encrypted, key, {
//       iv,
//       padding: CryptoJS.pad.Pkcs7,
//       mode: CryptoJS.mode.CBC,
//     })

//     return decrypted.toString(CryptoJS.enc.Utf8)
//   } catch (error) {
//     console.error(error)
//     return ''
//   }
// }