import { Outlet } from "react-router-dom"
import { AppBar, Drawer, Toolbar, Box, IconButton, Typography } from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"
import { useEffect, useState } from "react"
import DrawerContents from "./components/DrawerContents/DrawerContents"

const drawerWidth = 240

export default function Main() {
  const [mobileOpen, setMobileOpen] = useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  useEffect(()=>{
    if (
      !(window as any).googleTranslateElementInit
      && -1===navigator.language.indexOf('en')
    ) {
      const addScript = document.createElement('script');
      addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
      document.body.appendChild(addScript);
      (window as any).googleTranslateElementInit = googleTranslateElementInit
    }
  }, [])
  const googleTranslateElementInit = () => {
    new (window as any).google.translate.TranslateElement({ pageLanguage: 'en' }, 'google_translate_element')
  }

  return (
    <Box sx={{ display: "flex" }}>

      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` }
        }}
      >
        <Toolbar sx={{display: 'flex'}}>
          <IconButton
            // color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div"
            sx={{flexGrow: 1}}
          >
            Blockchain Tools
          </Typography>
          <Box id="google_translate_element"></Box>
        </Toolbar>
      </AppBar>

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={undefined}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth
            }
          }}
        >
          <DrawerContents setMobileOpenFalse={()=>setMobileOpen(false)} showCloseBtn />
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth
            }
          }}
          open
        >
          <DrawerContents setMobileOpenFalse={()=>setMobileOpen(false)} />
        </Drawer>
      </Box>
      
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 2,
          width: { sm: `calc(100% - ${drawerWidth}px)`, boxSizing: 'border-box' }
        }}
        
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  )
}