import {TextField, Typography, Button, IconButton, Link} from '@mui/material'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useEffect, useState } from 'react';
import {Currency, ChainInfo} from '@keplr-wallet/types'
import { cloneDeep } from 'lodash';

let chainInfo: ChainInfo
// let chainInfoRecoveried = false

export default function Keplr() {
  // states
  const [chainId, setChainId] = useState('')
  const [chainName, setChainName] = useState('')
  const [prefix, setPrefix] = useState('')
  const [rpc, setRpc] = useState('')
  const [rest, setRest] = useState('')
  const [walletUrlForStaking, setStakingUrl] = useState('')
  const [mainCurrency, setMainCurrency] = useState<Currency>({coinDenom: '', coinMinimalDenom: '', coinDecimals: NaN, coinGeckoId: ''}) // used by stakeCurrency and feeCurrencies; must exists, so set a default value
  const [otherCurrencies, setOtherCurrencies] = useState<Array<Currency>>([])
  const [low, setGasLow] = useState('0')
  const [average, setGasAverage] = useState('0.025')
  const [high, setGasHeigh] = useState('0.04')

  // effects
  // useEffect(()=>{
  //   // if (!chainInfoRecoveried) return
  //   // updateChainInfo()
  // }, [chainId, chainName, prefix, decimals, rpc, rest, walletUrlForStaking, mainCurrency, otherCurrencies])

  useEffect(()=>{
    // read chainInfo from localStorage and set all states
    const chainInfoString = localStorage.getItem('chainInfo')
    // return
    // console.log('chainInfoString', chainInfoString, typeof chainInfoString)
    if ('undefined'!==chainInfoString && null!==chainInfoString && chainInfoString?.trim().length>0) {
      try {
        const _chainInfo: ChainInfo = JSON.parse(chainInfoString)
        // console.log('_chainInfo', _chainInfo)
        // console.log('_chainInfo.chainId', _chainInfo.chainId)
        setChainId(_chainInfo.chainId||'')
        setChainName(_chainInfo.chainName||'')
        setPrefix(_chainInfo.bech32Config?.bech32PrefixAccAddr||'')
        // setDecimals(_chainInfo.feeCurrencies[0].coinDecimals.toString())
        setRpc(_chainInfo.rpc||'')
        setRest(_chainInfo.rest||'')
        setStakingUrl(_chainInfo.walletUrlForStaking||'')
        let _mainCurrency:Currency = _chainInfo.stakeCurrency
        !_mainCurrency.coinGeckoId&&((_mainCurrency.coinGeckoId as any)='')
        setMainCurrency(_mainCurrency)
        const _otherCurrencies: Currency[] = _chainInfo.currencies?.filter(currency=>{
          return currency.coinDenom !== _chainInfo.stakeCurrency?.coinDenom
        })
        setOtherCurrencies(_otherCurrencies)
        setGasLow(_chainInfo?.gasPriceStep?.low.toString()||'0')
        setGasAverage(_chainInfo?.gasPriceStep?.average.toString()||'0')
        setGasHeigh(_chainInfo?.gasPriceStep?.high.toString()||'0')
      } catch (e:any) {
        console.error('error converting localStorage', e)
      }
    }
    // chainInfoRecoveried = true

    const inputDoms = document.getElementsByTagName('input')
    for (let i=0; i<inputDoms.length; i++) {
      inputDoms[i].autocomplete = 'off'
    }
  }, [])

  // funcs
  function submit() {
    if (!window.keplr) {
      alert('Please install Keplr wallet first') // use mui popup
      return
    }
    updateChainInfo()
    // console.log('chainInfo', chainInfo)
    // return
    window.keplr.experimentalSuggestChain(chainInfo).catch((error: any) => {
      const errorMessage = error.message.replace('"stakeCurrency.coinDenom"', "Main currency's denom")
        .replace('"stakeCurrency.coinMinimalDenom"', "Main currency's minimal denom")
        .replace('"bech32Config.bech32PrefixAccAddr"', "Prefix")
      alert(errorMessage) // use mui popup
    })
  }

  function updateChainInfo() {
    // combine mainCurrency and otherCurrencies
    let currencies: Currency[] = [];
    let denoms: string[] = [];
    denoms.push(mainCurrency.coinDenom)
    currencies.push(mainCurrency)
    ''===mainCurrency.coinGeckoId?.trim()&&((mainCurrency.coinGeckoId as any)=undefined); // if coinGeckoId is empty string, set it to undefined
    (isNaN(mainCurrency.coinDecimals)||null===mainCurrency.coinDecimals) && ((mainCurrency.coinDecimals as number)=0)
    otherCurrencies.forEach(otherCurrency => {
      if (-1===denoms.indexOf(otherCurrency.coinDenom)) { // remove duplicated ones
        (isNaN(otherCurrency.coinDecimals)||null===otherCurrency.coinDecimals) && ((otherCurrency.coinDecimals as number)=0)
        denoms.push(otherCurrency.coinDenom)
        currencies.push(otherCurrency)
      }
    })
    chainInfo = {
      chainId,
      chainName,
      rpc,
      rest,
      bip44: {
        coinType: 118,
      },
      bech32Config: {
        bech32PrefixAccAddr: prefix,
        bech32PrefixAccPub: prefix + "pub",
        bech32PrefixValAddr: prefix + "valoper",
        bech32PrefixValPub: prefix + "valoperpub",
        bech32PrefixConsAddr: prefix + "valcons",
        bech32PrefixConsPub: prefix + "valconspub",
      },
      currencies,
      feeCurrencies: [mainCurrency],
      stakeCurrency: mainCurrency,
      coinType: 118,
      gasPriceStep: {
        low: Number(low)<0?0:Number(low),
        average: Number(average)<0?0:Number(average),
        high: Number(high)<0?0:Number(high),
      },
      walletUrlForStaking: ''===walletUrlForStaking?undefined:walletUrlForStaking, // if staking url is empty string, set to undefined
      features: ['no-legacy-stdTx', 'ibc-transfer', 'ibc-go']
    }
    localStorage.setItem('chainInfo', JSON.stringify(chainInfo))
  }

  function addOtherCurrenciesClick() {
    setOtherCurrencies([
      ...otherCurrencies,
      {
        coinDenom: '',
        coinMinimalDenom: '',
        coinDecimals: NaN,
      }
    ])
  }

  function removeOtherCurrenciesClick(index: number) {
    let _otherCurrencies = cloneDeep(otherCurrencies)
    _otherCurrencies.splice(index, 1)
    setOtherCurrencies(_otherCurrencies)
  }

  function inputChange(value: string, inputName: 'coinDenom'|'coinMinimalDenom'|'coinGeckoId'|'coinDecimals', index?:number, isMain?:boolean) {
    if (isMain) { // setMainCurrency 
      let _mainCurrency: Currency = cloneDeep(mainCurrency)
      if ('coinDecimals'===inputName) {
        let coinDecimals = Number(value)
        if (0===value.trim().length) {
          coinDecimals = NaN // The specified value "NaN" cannot be parsed, or is out of range.
        }
        (_mainCurrency[inputName] as number) = coinDecimals
      } else {
        (_mainCurrency[inputName] as string) = value // lodash cloneDeep Cannot assign to ' ' because it is a read-only property.ts(2540)
      }
      setMainCurrency(_mainCurrency)
    } else { // setOtherCurrencies
      if (undefined===index) return
      let _otherCurrencies = cloneDeep(otherCurrencies)
      if ('coinDecimals'===inputName) {
        let coinDecimals = Number(value)
        if (0===value.trim().length) {
          coinDecimals = NaN
        }
        (_otherCurrencies[index][inputName] as number) = coinDecimals
      } else {
        (_otherCurrencies[index][inputName] as string) = value
      }
      setOtherCurrencies(_otherCurrencies)
    }
  }

  return (
    <div style={{maxWidth: '800px', minHeight: '62vh', margin: '0 auto'}}>
      <Typography variant="h3" component="div" gutterBottom>
        Add custom chain to Keplr
      </Typography>

      <div className='flex justify-between'>
        <TextField label="Chain Id" onChange={(e)=>setChainId(e.target.value.trim())} value={chainId} required fullWidth />
        <TextField label="Chain Name" onChange={(e)=>setChainName(e.target.value.trim())} value={chainName} className='mx-4' required fullWidth />
        <TextField label="Prefix" onChange={(e)=>setPrefix(e.target.value.trim())} value={prefix} placeholder='eg: cosmos' required fullWidth />
      </div>
      <Typography variant="body2" component="div">
        Chain Id could be found in rpc-url/status, on network field
      </Typography>
      <div className='flex justify-between mt-4'>
        {/* <TextField label="Prefix" onChange={(e)=>setPrefix(e.target.value.trim())} value={prefix} className='mr-4' placeholder='eg: cosmos' required fullWidth /> */}
        {/* <TextField onChange={(e)=>setDecimals(e.target.value)} value={decimals} placeholder='0' label="Currencies' Decimals" type='number' required fullWidth /> */}
      </div>
      <TextField label="RPC URL" onChange={(e)=>setRpc(e.target.value.trim())} value={rpc} className='mt-4' required fullWidth />
      <TextField label="REST URL" onChange={(e)=>setRest(e.target.value.trim())} value={rest} className='mt-4' required fullWidth />
      <TextField label="Staking URL" onChange={(e)=>setStakingUrl(e.target.value.trim())} value={walletUrlForStaking} className='mt-4 mb-4' placeholder='optional, eg: https://wallet.keplr.app/#/cosmoshub/stake' fullWidth />

      <Typography variant="h5" component="div">
        Main currency (Gas fee currency)
      </Typography>
      <Typography variant="body2" component="div">
        CoinGecko id is optional, if inputed, keplr will show USD value
      </Typography>
      <DenomInputs isMain currencyInfo={mainCurrency}
          inputChange={(value: string, inputName: 'coinDenom'|'coinMinimalDenom'|'coinGeckoId', index?:number, isMain?:boolean)=>inputChange(value, inputName, index, isMain)}
        />

      <Typography variant="h5" className='mt-4' component="div">
        Other currencies
      </Typography>
      <Typography variant="body2" component="div">
        These currencies will be shown in keplr
      </Typography>
      {otherCurrencies?.map((otherCurrency, index)=>(
        <DenomInputs currencyInfo={otherCurrency} index={index} removeOtherCurrenciesClick={(index: number)=>removeOtherCurrenciesClick(index)}
          inputChange={(value: string, inputName: 'coinDenom'|'coinMinimalDenom'|'coinGeckoId', index?:number, isMain?:boolean)=>inputChange(value, inputName, index, isMain)}
          key={`otherCurrencies-${index}`} />
      ))}
      <IconButton onClick={()=>{addOtherCurrenciesClick()}}><AddCircleIcon/></IconButton>

      <Typography variant="h5" className='mt-4' component="div">
        Gas Price Steps
      </Typography>
      <div className='flex justify-between mt-2'>
        <TextField label="Low" error={Number(low)<0} onChange={(e)=>setGasLow(e.target.value)} value={low} type='number' fullWidth />
        <TextField label="Average" error={Number(average)<0} onChange={(e)=>setGasAverage(e.target.value)} value={average} className='mx-4' type='Number' fullWidth />
        <TextField label="Height" error={Number(high)<0} onChange={(e)=>setGasHeigh(e.target.value)} value={high} type='number' fullWidth />
      </div>

      <div className='mt-4'><Button onClick={()=>submit()} variant='contained' className='w-5/12' size='large' fullWidth>Submit</Button></div>

      <Typography variant='body1' className='my-8' component="div">
        Usage: 
        <div><Link href='https://docs.keplr.app/api/suggest-chain.html' target='_blank'>https://docs.keplr.app/api/suggest-chain.html</Link></div>
        <div><Link href='https://github.com/chainapsis/keplr-wallet/blob/master/packages/types/src/chain-info.ts' target='_blank'>https://github.com/chainapsis/keplr-wallet/blob/master/packages/types/src/chain-info.ts</Link></div>
      </Typography>
    </div>
  )
}

function DenomInputs(props: {isMain?: boolean, currencyInfo: Currency|undefined, index?: number, 
  removeOtherCurrenciesClick?: Function, inputChange: Function
}) {
  return (
    <div className='flex justify-between items-center mt-2'>
      <TextField onChange={(e:any)=>props.inputChange(e.target.value.trim(), 'coinDenom', props.index, props.isMain)} value={props.currencyInfo?.coinDenom} autoComplete='off' label="Denom" className='mr-4' placeholder='eg: ATOM' required fullWidth />
      <TextField onChange={(e:any)=>props.inputChange(e.target.value.trim(), 'coinMinimalDenom', props.index, props.isMain)} value={props.currencyInfo?.coinMinimalDenom} autoComplete='off' label="Minimal denom" placeholder='eg: uatom' required fullWidth />
      <TextField onChange={(e:any)=>props.inputChange(e.target.value.trim(), 'coinDecimals', props.index, props.isMain)} value={props.currencyInfo?.coinDecimals?.toString()} autoComplete='off' placeholder='0' label="decimals" type='number' className='ml-4' required fullWidth />
      <TextField onChange={(e:any)=>props.inputChange(e.target.value.trim(), 'coinGeckoId', props.index, props.isMain)} value={props.currencyInfo?.coinGeckoId} autoComplete='off' label="CoinGecko id" className='ml-4' placeholder='optional' fullWidth />
      {!props.isMain&&<div><IconButton onClick={()=>props.removeOtherCurrenciesClick&&props.removeOtherCurrenciesClick(props.index)}><RemoveCircleIcon/></IconButton></div>}
    </div>
  )
}